<template>
  <div />
</template>
<script>
import * as introJs from 'intro.js/minified/intro.min'
import 'intro.js/minified/introjs.min.css'
import { TOOLKIT_PAGE } from '@/constants'
import { mapState } from 'vuex'
import { SET_IS_TOOLKIT_FOR_WALLET_REFERRALS, SET_SHOULD_DISPLAY_TOOLKIT_POPUP } from '@/store/modules/toolkit.module'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { FETCH_PROFILE } from '@/store/modules/auth.module'
import { apiToastWarning } from '@/@core/utils/toast'
import { steps } from '../../../toolkit-contant'

const { BRAND_ACCESS } = constants

export default {
  name: 'ToolKit',
  props: {
    refId: {
      type: String,
      default() {
        return ''
      },
    },
    toolkitPage: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      stepsLocal: this.$store.getters.isRetailer ? steps.retailer : steps.brand,
      page: this.toolkitPage,
    }
  },
  computed: {
    ...mapState({
      shouldDisplayToolkitPopup: state => state.toolkit.shouldDisplayToolkitPopup,
      isToolkitForWalletReferrals: state => state.toolkit.isToolkitForWalletReferrals,
    }),
    isRetailer() {
      return this.$store.getters.isRetailer
    },
  },
  created() {
    if (this.refId) {
      this.$root.$refs[this.refId] = this
    }
    const data = this.$store.getters.profile
    if (
      (data && data.shouldDisplayToolKit) || this.shouldDisplayToolkitPopup
    ) {
      this.startTour(this.page)
    }
  },
  methods: {
  listenCloseEvent() {
    const elements = document.getElementsByClassName('introjs-skipbutton')
    Array.from(elements).forEach(element => {
      element.addEventListener('click', () => {
        this.$store.commit(SET_SHOULD_DISPLAY_TOOLKIT_POPUP, false)
        this.$store.commit(SET_IS_TOOLKIT_FOR_WALLET_REFERRALS, false)
      })
    })
  },
    expandSideBar() {
      document.getElementById('left_side_navbar').classList.add('expanded')
    },
    collapseSideBar() {
      document.getElementById('left_side_navbar').classList.remove('expanded')
    },
    expandOrCollapseExplore() {
      document
        .getElementById('intro_explore')
        .getElementsByTagName('a')[0]
        .click()
    },
    isWalletOrReferrals(pageName) {
      return [TOOLKIT_PAGE.WALLET, TOOLKIT_PAGE.REFERRALS].includes(pageName)
    },
    async completeTour() {
      try {
        await this.$kpRequest({ ...this.$kpEndpoint.profile.closeToolkit, payload: {} })
        await this.$store.dispatch(FETCH_PROFILE)
      } catch (err) {
          console.error('Error completing toolkit', err)
          apiToastWarning(err)
      }
    },
    goToDashboard() {
      if (this.isToolkitForWalletReferrals) {
        this.$store.commit(SET_SHOULD_DISPLAY_TOOLKIT_POPUP, false)
        this.$store.commit(SET_IS_TOOLKIT_FOR_WALLET_REFERRALS, false)
      }
      else {
        this.$router.push({ path: '/dashboard' })
        this.startTour(TOOLKIT_PAGE.DASHBOARD)
      }
    },
    getDoneText(pageName) {
      return pageName === TOOLKIT_PAGE.DASHBOARD || (this.isToolkitForWalletReferrals && pageName === TOOLKIT_PAGE.WALLET) ? 'Done' : 'Next'
    },
    startTour(pageName) {
      if (pageName === TOOLKIT_PAGE.WELCOME_TO_KINGPIN) {
        this.$store.commit(SET_SHOULD_DISPLAY_TOOLKIT_POPUP, true)
      }

      const tooltipClass = `${ this.isWalletOrReferrals(pageName) ? 'toolkit-for-wallet-referrrals' : 'custom-toolkit'} font-manrope`
      // get steps from toolkit-content.js by page name
      const introJsOptions = {
        steps: this.stepsLocal[pageName],
        exitOnOverlayClick: false,
        showBullets: false,
        prevLabel: this.isWalletOrReferrals(pageName) ? 'Back' : 'Prev',
        nextLabel: 'Next',
        doneLabel: this.getDoneText(pageName),
        hidePrev: true,
        skipLabel: pageName === TOOLKIT_PAGE.WELCOME_TO_KINGPIN ? '' : 'x',
        disableInteraction: true,
        keyboardNavigation: true,
        tooltipClass,
      }
      // intro.js
      const intro = introJs()
      intro.setOptions(introJsOptions)

      setTimeout(() => {
        intro.start()
        this.listenCloseEvent()
      }, 1000)
      let directBrands = []
      const profile = this.$store.getters.profile
      let isBrandsAccessible = true
      if (this.isRetailer) {
        directBrands = this.$store.getters.directBrands
        isBrandsAccessible = profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
      }
      intro.oncomplete(async () => {
        this.page = pageName
        if (this.shouldDisplayToolkitPopup) {
        switch (this.page) {
          case TOOLKIT_PAGE.WELCOME_TO_KINGPIN:
            await this.$router.push({ path: '/profile' })
            break
          case TOOLKIT_PAGE.PROFILE:
            if (this.isRetailer) {
              // expand the side bar programatically
              this.completeTour()
              if (isBrandsAccessible) {
                // click the explore button programatically
                this.expandSideBar()
                this.expandOrCollapseExplore()
                await this.$router.push({ path: '/explore' })
              }
              else if (directBrands.length) {
                  const [directBrand] = directBrands
                  await this.$router.push({ name: 'brand/detail', params: { brandId: directBrand._id } })
                } else {
                  await this.$router.push({ name: 'wishlist' })
                }
            } else {
              this.completeTour()
              await this.$router.push({ path: '/collections' })
            }
            break
          case TOOLKIT_PAGE.BRANDS_EXPLORE:
            document.getElementById('brand0').click()
            break
          case TOOLKIT_PAGE.BRAND_PROFILE:
            document
              .getElementById('collection0')
              .click()
            break
          case TOOLKIT_PAGE.SHOP_PRODUCTS:
            await this.$router.push({ path: '/wishlist' })
            break
          case TOOLKIT_PAGE.WISHLIST:
            await this.$router.push({ path: '/checkout' })
            break
          case TOOLKIT_PAGE.CHECKOUT:
            await this.$router.push({ path: '/orders' })
            break
          case TOOLKIT_PAGE.ORDERS:
            await this.$router.push({ path: '/referrals' })
            this.expandSideBar()
            break
          case TOOLKIT_PAGE.DASHBOARD:
            this.$store.commit(SET_SHOULD_DISPLAY_TOOLKIT_POPUP, false)
            this.completeTour()
            break
          case TOOLKIT_PAGE.BRAND_LANDING_PAGE:
            this.completeTour()
            await this.$router.push({ path: '/collections' })
            this.expandSideBar()
            break
          case TOOLKIT_PAGE.COLLECTIONS:
            this.collapseSideBar()
            document.getElementById('intro_add_collection_button').click()
            break
          case TOOLKIT_PAGE.COLLECTION_EDIT:
            await this.$router.push({ path: '/orders' })
            break
          case TOOLKIT_PAGE.REFERRALS:
            await this.$router.push({ path: '/wallet' })
            this.expandSideBar()
            break
          case TOOLKIT_PAGE.WALLET:
          this.goToDashboard()
          break
          default:
            break
        }
        }
      })

      intro.onchange(targetElement => {
        if (pageName === TOOLKIT_PAGE.PROFILE && targetElement.id !== 'intro_preview') {
          document.getElementById(targetElement.id).click()
        }
        if (pageName === TOOLKIT_PAGE.WALLET) {
          if (targetElement.id === 'intro_transactions_tab') {
            this.$router.push({ path: '/wallet/transactions' })
          }
          if (targetElement.id === 'intro_savings_tab') {
            this.$router.push({ path: '/wallet/savings' })
          }
          if (targetElement.id === 'intro_rewards_tab') {
            this.$router.push({ path: '/wallet/rewards' })
          }
        }
        if (['intro_transactions_tab', 'intro_savings_tab', 'intro_referral_button'].includes(targetElement.id)) {
            this.collapseSideBar()
        }
        if (
          pageName === TOOLKIT_PAGE.BRANDS_EXPLORE
          && targetElement.id === 'intro_filters'
        ) {
          this.expandOrCollapseExplore()
          this.collapseSideBar()
        }
      })

      intro.onexit(() => {
        this.completeTour()
      })
    },
  },
}
</script>

<style lang="scss">
@import './tool-kit.scss';
</style>
